import { I18n } from "./I18n";
export function isYoutubeEnabled() {
    return localStorage.getItem('oebb-yt-videos') ? true : false;
}
export function toggleYoutubeLocalstorage() {
    if (isYoutubeEnabled()) {
        localStorage.removeItem('oebb-yt-videos');
    }
    else {
        localStorage.setItem('oebb-yt-videos', "true");
    }
}
export function changeDisclaimerBtnText(disclaimerButton, lang) {
    if (!disclaimerButton)
        return;
    const i18n = new I18n(lang);
    if (isYoutubeEnabled()) {
        disclaimerButton.classList.remove('button--primary');
        disclaimerButton.classList.add('button--link');
        disclaimerButton.textContent = i18n.getProperty('video-deactivate', "YouTube deaktivieren");
    }
    else {
        disclaimerButton.classList.remove('button--link');
        disclaimerButton.classList.add('button--primary');
        disclaimerButton.innerHTML = '<span class="ic-play-white"></span> ' +
            i18n.getProperty('video-activate', "YouTube aktivieren");
    }
}
export function createDisclaimerBtn() {
    let disclaimerBtn = document.createElement("button");
    disclaimerBtn.classList.add('js-disclaimer-btn');
    disclaimerBtn.classList.add('video__disclaimer-btn');
    disclaimerBtn.classList.add('button');
    disclaimerBtn.classList.add('button--align-right');
    return disclaimerBtn;
}
export function createDisclaimerTxt(lang) {
    const i18n = new I18n(lang);
    let disclaimer = document.createElement("p");
    disclaimer.classList.add('video__disclaimer-txt');
    disclaimer.innerHTML = i18n.getProperty('video-disclaimer', '');
    return disclaimer;
}
