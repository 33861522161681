function jsCheck() {
    document.documentElement.className = document.documentElement.className.replace(/\bno-js\b/, 'js');
}
function removeClassByName(className) {
    Array.prototype.map.call(document.querySelectorAll(`.${className}`), function (el) {
        el.classList.remove(className);
    });
}
function isMobileViewport() {
    return (window.innerWidth < 768);
}
function isTabletViewport() {
    return ((window.innerWidth < 920 && window.innerWidth >= 768));
}
function isDesktopViewport() {
    return (window.innerWidth >= 920);
}
function shortenText(str, maxLength) {
    const cut = str.indexOf(' ', maxLength);
    if (cut == -1)
        return str;
    return str.substring(0, cut) + ' [...]';
}
function getAge(birthday) {
    const timeDiff = Math.abs(Date.now() - birthday.getTime());
    return Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
}
function formatAge() {
    const ageElements = document.querySelectorAll('.format-age');
    for (let i = 0; i < ageElements.length; i++) {
        const elem = ageElements.item(i), birthday = new Date(elem.innerHTML);
        elem.innerHTML = getAge(birthday).toString();
    }
}
function generateTeaser(template, fields) {
    let teaser = ``;
    teaser += `<div class="col-sm-6 col-md-4">`;
    teaser += template.innerHTML
        .replace('{{linkHref}}', () => {
        return fields.href;
    })
        .replace('{{imageSrc}}', fields.imageSrc ? fields.imageSrc : '/.resources/rcg-2019/themes/images/unsere-oebb-placeholder.png')
        .replace('{{imageSrcSet}}', fields.imageSrcSet ? fields.imageSrcSet : '/.resources/rcg-2019/themes/images/unsere-oebb-placeholder.png, /.resources/rcg-2019/themes/images/unsere-oebb-placeholder-2x.png 2x')
        .replace('{{imageLazySrc}}', fields.imageLazySrc ? fields.imageLazySrc : '/.resources/rcg-2019/themes/images/unsere-oebb-placeholder.png')
        .replace('{{imageAlt}}', fields.imageAlt)
        .replace('{{imageTitle}}', fields.imageTitle)
        .replace('{{category}}', fields.category)
        .replace(/{{title}}/g, fields.title)
        .replace('{{leadin}}', () => {
        let leadin = fields.leadin.replace(/(<([^>]+)>)/ig, "");
        let suffix = ' [...]';
        if (leadin.length > 90) {
            leadin = leadin.substr(0, 90 - suffix.length);
            leadin = leadin.substr(0, Math.min(leadin.length, leadin.lastIndexOf(' '))) + suffix;
        }
        return leadin;
    });
    teaser += `</div>`;
    return teaser;
}
function sendRequest(requestType, url, callback, callbackError, requestHeader = null) {
    var xhr = new XMLHttpRequest();
    xhr.open(requestType, encodeURI(url));
    if (requestHeader) {
        let objKey = Object.keys(requestHeader)[0];
        xhr.setRequestHeader(objKey, requestHeader[objKey]);
    }
    xhr.onerror = function (ev) {
        callbackError(ev);
    };
    xhr.onreadystatechange = ensureReadiness;
    function ensureReadiness() {
        if (xhr.readyState < 4) {
            return;
        }
        if (xhr.status !== 200) {
            return;
        }
        // all is well
        if (xhr.readyState === 4) {
            callback(xhr);
        }
    }
    xhr.send(null);
}
function post(url, callback, callbackError, requestHeader = null) {
    sendRequest('POST', url, callback, callbackError, requestHeader);
}
function load(url, callback, callbackError, requestHeader = null) {
    sendRequest('GET', url, callback, callbackError, requestHeader);
}
function beautifyControlFile(groupElement) {
    if (!groupElement)
        return;
    const fileElement = groupElement.querySelector('input[type="file"]');
    const fileFakeElement = document.createElement('label');
    const lang = document.documentElement.lang ? document.documentElement.lang : 'de';
    const labels = {
        'de': {
            'select': 'Datei auswählen...',
            'multiple': 'Dateien ausgewählt.',
        },
        'en': {
            'select': 'Select file...',
            'multiple': 'Files selected.',
        }
    };
    fileElement.style.width = '0';
    fileElement.style.height = '0';
    fileElement.style.margin = '0';
    fileElement.style.padding = '0';
    fileElement.style.border = '0';
    fileElement.style.opacity = '0';
    fileElement.addEventListener('focus', function () {
        this.parentElement.classList.add('has-focus');
    });
    fileElement.addEventListener('blur', function () {
        this.parentElement.classList.remove('has-focus');
    });
    fileElement.addEventListener('change', function (e) {
        let fileName;
        if (this.files) {
            fileName = e.target.value.split('\\').pop();
            if (this.files.length > 1)
                fileName = this.files.length + ' ' + labels[lang]['multiple'];
        }
        if (fileName)
            fileFakeElement.textContent = fileName;
    });
    fileFakeElement.htmlFor = fileElement.id;
    fileFakeElement.style.cursor = 'pointer';
    fileFakeElement.classList.add('control-text');
    fileFakeElement.textContent = labels[lang]['select'];
    fileElement.parentNode.insertBefore(fileFakeElement, fileElement.nextSibling);
}
function addCounterToTextareasWithMaxLength() {
    const textareas = document.querySelectorAll('textarea[maxlength]');
    for (let i = 0; i < textareas.length; i++) {
        const textarea = textareas[i];
        const counter = document.createElement('small');
        counter.style.display = 'block';
        counter.innerHTML = `${textarea.textContent.length} / ${textarea.getAttribute('maxlength')}`;
        textarea.parentNode.insertBefore(counter, textarea.nextSibling);
        textarea.addEventListener('keyup', function () {
            counter.innerHTML = `${this.value.length} / ${this.getAttribute('maxlength')}`;
        });
    }
}
function tabbingClass(className) {
    document.addEventListener('keydown', function (e) {
        if (e.keyCode !== 9)
            return;
        document.documentElement.classList.add(className);
    });
    document.addEventListener('mousedown', function () {
        document.documentElement.classList.remove(className);
    });
}
function addRoleButtonListener() {
    let elementsRoleButton = document.querySelectorAll('[role="button"]');
    for (let i = 0; i < elementsRoleButton.length; i++) {
        elementsRoleButton[i].addEventListener('keydown', (e) => {
            if (e.keyCode === 13 || e.keyCode === 32) {
                e.preventDefault();
                elementsRoleButton[i].click();
            }
        }, false);
    }
}
function createElement(tagName, classNames = []) {
    let createdElement = document.createElement(tagName);
    for (let i = 0; classNames.length > i; i++) {
        createdElement.classList.add(classNames[i]);
    }
    return createdElement;
}
function formatDate(date) {
    return (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "." + (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)) + "." + date.getFullYear();
}
function getOffset(el) {
    const rect = el.getBoundingClientRect();
    return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY
    };
}
/**
 * Load a JavaScript file
 *
 * The callback stack is used to collect all callback functions
 * and call it at the same time to avoid callbacks too early.
 * Example: Creating three PoiMap Objects on the same page. Every Object tries to load Leaflet
 * but because of the used ID Leaflet is included only once (multiple Leaflet scripts would not make sense).
 * But every PoiMap needs to know when Leaflet is ready, therefore all callbacks are collected and called after
 * the load event of the script.
 *
 * @param src Path to script
 * @param id (Optional) ID for the script to prevent multiple scripts.
 * @param onLoadCallback (Optional) Required "id" parameter. Callack when script is loaded.
 */
function loadScript(src, id, onLoadCallback, type) {
    if (!window.OebbScripts) {
        /**
         * Script loading global object with "OebbScripts" namespace.
         */
        window.OebbScripts = {
            /**
             * Script loading callback Stack.
             * Object with "id" and "callback".
             */
            scriptCallbackStack: [],
            /**
             * Check if callback stack has been executed.
             * If the script is already on the page and a class need it later
             * the callback should be executed therefore we need to know if the
             * callbacks from the stack have been executed.
             */
            executedCallbacksById: [],
        };
    }
    const oebbScripts = window.OebbScripts;
    if (id && document.querySelector(`#${id}`)) {
        if (onLoadCallback && oebbScripts.executedCallbacksById.indexOf(id) == -1) {
            oebbScripts.scriptCallbackStack.push({
                id: id,
                callback: onLoadCallback
            });
        }
        else if (onLoadCallback) {
            if (oebbScripts.executedCallbacksById.indexOf(id) == -1)
                oebbScripts.executedCallbacksById.push(id);
            onLoadCallback();
        }
        return;
    }
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.defer = true;
    if (id)
        script.id = id;
    if (type && type == 'module')
        script.type = 'module';
    if (type && type == 'nomodule')
        script.noModule = true;
    if (id && onLoadCallback) {
        oebbScripts.scriptCallbackStack.push({
            id: id,
            callback: onLoadCallback
        });
    }
    script.addEventListener('load', function () {
        if (oebbScripts.scriptCallbackStack.length) {
            for (const callbackObject of oebbScripts.scriptCallbackStack) {
                if (callbackObject.id == this.id) {
                    callbackObject.callback();
                }
            }
            if (oebbScripts.executedCallbacksById.indexOf(id) == -1)
                oebbScripts.executedCallbacksById.push(id);
        }
        else {
            if (onLoadCallback)
                onLoadCallback();
        }
    });
    document.body.appendChild(script);
}
function getResourceUrl(chunk) {
    const separator = chunk.lastIndexOf('.');
    const basename = chunk.substring(0, separator);
    const extension = chunk.substring(separator);
    return __webpack_public_path__ + basename + (__resource_suffix__ || '') + extension;
}
export { jsCheck, removeClassByName, isMobileViewport, isDesktopViewport, isTabletViewport, shortenText, getAge, formatAge, generateTeaser, load, post, tabbingClass, beautifyControlFile, addCounterToTextareasWithMaxLength, addRoleButtonListener, createElement, formatDate, getOffset, loadScript, getResourceUrl };
