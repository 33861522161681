export class Waterfall {
    fadeOut(elementSelector) {
        return TweenMax.fromTo(elementSelector, 1, {
            opacity: 1,
            ease: Power1.easeInOut
        }, {
            opacity: 0,
            ease: Power1.easeInOut
        });
    }
    scrollIn(elementSelector) {
        const yPercent = document.querySelector(elementSelector).classList.contains('half-h') ? 200 : 100;
        return TweenMax.fromTo(elementSelector, 1, {
            yPercent: yPercent,
            ease: Power1.easeInOut
        }, {
            yPercent: 0,
            ease: Power1.easeInOut
        });
    }
    scrollInRight(elementSelector) {
        return TweenMax.fromTo(elementSelector, 1, {
            xPercent: 100,
            ease: Power1.easeInOut
        }, {
            xPercent: 0,
            ease: Power1.easeInOut
        });
    }
    scrollInLeft(elementSelector) {
        return TweenMax.fromTo(elementSelector, 1, {
            xPercent: -100,
            ease: Power1.easeInOut
        }, {
            xPercent: 0,
            ease: Power1.easeInOut
        });
    }
    constructor(element) {
        this.controller = new ScrollMagic.Controller();
        const parentSelector = '#' + element.getAttribute('id');
        let effects = element.querySelectorAll('.waterfall__effect');
        let childSelector;
        for (let i = 0; i < effects.length; i++) {
            let offset = 1000 * i;
            childSelector = '#' + effects[i].getAttribute('id');
            new ScrollMagic.Scene({
                duration: 1000,
                triggerElement: parentSelector,
                triggerHook: 0,
                offset: offset
            })
                .setPin(parentSelector) // pins the element for the the scene's duration
                .setTween(this.getScrollEffectType(effects[i])(childSelector))
                .addTo(this.controller); // assign the scene to the controller
        }
    }
    getScrollEffectType(element) {
        const effectType = element.getAttribute('data-scroll-effect');
        switch (effectType) {
            case "fadeOut":
                return this.fadeOut;
            case "scrollIn":
                return this.scrollIn;
            case "scrollInRight":
                return this.scrollInRight;
            case "scrollInLeft":
                return this.scrollInLeft;
            default:
                return this.fadeOut;
        }
    }
    static addScript(onLoadCallback) {
        if (!document.querySelector('js-tweenmax')) {
            let scriptTweenmax = document.createElement('script');
            scriptTweenmax.async = true;
            scriptTweenmax.defer = true;
            scriptTweenmax.src = '/.resources/rcg-2019/themes/js/vendor/tweenmax.min.js?refresh=1';
            scriptTweenmax.classList.add('js-tweenmax');
            scriptTweenmax.addEventListener('load', () => {
                if (!document.querySelector('js-scrollmagic')) {
                    let scriptScrollmagic = document.createElement('script');
                    scriptScrollmagic.async = true;
                    scriptScrollmagic.defer = true;
                    scriptScrollmagic.src = '/.resources/rcg-2019/themes/js/vendor/scrollmagic.min.js?refresh=1';
                    scriptScrollmagic.classList.add('js-scrollmagic');
                    scriptScrollmagic.addEventListener('load', () => {
                        if (!document.querySelector('js-animation')) {
                            let scriptAnimation = document.createElement('script');
                            scriptAnimation.async = true;
                            scriptAnimation.defer = true;
                            scriptAnimation.src = '/.resources/rcg-2019/themes/js/vendor/animation.gsap.min.js';
                            scriptAnimation.classList.add('js-animation');
                            scriptAnimation.addEventListener('load', () => {
                                onLoadCallback();
                            });
                            document.body.appendChild(scriptAnimation);
                        }
                    });
                    document.body.appendChild(scriptScrollmagic);
                }
            });
            document.body.appendChild(scriptTweenmax);
        }
    }
}
export function initWaterfall() {
    const sections = document.querySelectorAll('html:not(.edit-mode) .js-waterfall__section');
    if (sections.length > 0) {
        Waterfall.addScript(() => {
            for (let i = 0; i < sections.length; i++) {
                new Waterfall(sections[i]);
            }
        });
    }
}
