import animateScrollTo from 'animated-scroll-to';
import { getOffset } from "./Utils";
export class ScrollTop {
    constructor(element) {
        this.element = element;
        this.element.addEventListener('click', (event) => {
            event.preventDefault();
            const targetId = this.element.getAttribute('href');
            this.target = document.querySelector(targetId);
            if (this.target) {
                animateScrollTo(getOffset(this.target).top);
            }
        });
    }
}
export function initScrollTop() {
    const expandables = document.querySelectorAll('.scroll-top');
    for (let i = 0; i < expandables.length; i++) {
        new ScrollTop(expandables[i]);
    }
}
