export function lazyLoadImages() {
    let images = document.querySelectorAll('.js-lazy-image');
    if ('IntersectionObserver' in window
        && 'IntersectionObserverEntry' in window
        && 'intersectionRatio' in window.IntersectionObserverEntry.prototype
        && 'isIntersecting' in window.IntersectionObserverEntry.prototype) {
        let intersectionObserver = new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    const image = entry.target;
                    intersectionObserver.unobserve(image);
                    image.src = image.dataset.src;
                    image.srcset = image.dataset.srcset;
                    image.classList.remove('.js-lazy-image');
                }
            });
        }, { rootMargin: '300px' });
        for (let i = 0; i < images.length; i++) {
            intersectionObserver.observe(images[i]);
        }
    }
    else {
        // Fallback, just load all images
        for (let i = 0; i < images.length; i++) {
            const image = images[i];
            image.src = image.dataset.src;
            image.srcset = image.dataset.srcset;
        }
    }
}
