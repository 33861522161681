import { VisibilityObserver } from "./VisibilityObserver";
var Slide;
(function (Slide) {
    Slide["Next"] = "NEXT";
    Slide["Previous"] = "PREVIOUS";
})(Slide || (Slide = {}));
;
export class Carousel extends VisibilityObserver {
    /** Animation duration in ms, same in CSS transition */
    static get ANIMATION_DURATION() { return 750; }
    /** Auto Sliding timeout in ms */
    static get AUTO_SLIDING_TIMER() { return 5000; }
    constructor(slider) {
        super();
        this.currentIndex = 0;
        this.lastIndex = 0;
        this.itemsCount = 1;
        this.slidingDirection = Slide.Next;
        this.isAnimating = false;
        this.doesAutoSliding = false; // Auto Sliding state
        this.didAutoSliding = false; // Auto Sliding state before page is hidden
        this.stop = () => {
            this.stopAutoSliding();
        };
        this.slider = slider;
        this.slider.ref = this;
        this.items = slider.querySelectorAll('.js-carousel-list > div');
        this.indicators = slider.querySelectorAll('.js-carousel-indicators > a');
        this.itemsCount = this.items.length;
        this.initDom();
        this.initListeners();
    }
    initDom() {
        if (this.itemsCount == 1) {
            if (this.slider.querySelector('.js-carousel-next-slide')) {
                this.slider.querySelector('.js-carousel-next-slide').style.display = 'none';
            }
            if (this.slider.querySelector('.js-carousel-previous-slide')) {
                this.slider.querySelector('.js-carousel-previous-slide').style.display = 'none';
            }
            if (this.slider.querySelector('.js-carousel-play')) {
                this.slider.querySelector('.js-carousel-play').style.display = 'none';
            }
            if (this.indicators.length) {
                this.slider.querySelector('.js-carousel-indicators').style.display = 'none';
            }
        }
        else if (this.itemsCount > 1) {
            if (this.slider.classList.contains('js-carousel--auto-slide') && !document.getElementsByTagName('html')[0].classList.contains('edit-mode')) {
                this.toggleAutoSliding();
            }
            for (let i = 0; i < this.items.length; i++) {
                this.items[i].style.transition = `transform ${Carousel.ANIMATION_DURATION / 1000}s ease-out`;
            }
        }
        this.items[0].classList.add('carousel__item--active');
        if (this.indicators.length) {
            this.indicators[0].classList.add('carousel__indicator--active');
        }
    }
    initListeners() {
        if (this.slider.querySelector('.js-carousel-next-slide')) {
            this.slider.querySelector('.js-carousel-next-slide').addEventListener('click', (e) => {
                e.preventDefault();
                this.stopAutoSliding();
                this.triggerTransition(Slide.Next);
            });
        }
        if (this.slider.querySelector('.js-carousel-previous-slide')) {
            this.slider.querySelector('.js-carousel-previous-slide').addEventListener('click', (e) => {
                e.preventDefault();
                this.stopAutoSliding();
                this.triggerTransition(Slide.Previous);
            });
        }
        if (this.indicators.length) {
            for (let i = 0; i < this.indicators.length; i++) {
                this.indicators[i].addEventListener('click', (e) => {
                    e.preventDefault();
                    this.stopAutoSliding();
                    this.triggerTransition(i);
                });
            }
        }
        if (this.slider.querySelector('.js-carousel-play')) {
            this.slider.querySelector('.js-carousel-play').addEventListener('click', (e) => {
                e.preventDefault();
                this.toggleAutoSliding();
            });
        }
        let touchStartX = 0;
        let dragging = false;
        this.slider.addEventListener('touchstart', (e) => {
            touchStartX = e.touches[0].clientX;
            dragging = true;
        });
        // Swipe left/right
        this.slider.addEventListener('touchmove', (e) => {
            if (!dragging)
                return;
            let touchMove = e.changedTouches[0].clientX;
            let touchDiff = Math.abs(touchStartX - touchMove) > 80;
            if (touchStartX > touchMove && touchDiff) {
                this.stopAutoSliding();
                this.triggerTransition(Slide.Next);
                dragging = false;
            }
            else if (touchDiff) {
                this.stopAutoSliding();
                this.triggerTransition(Slide.Previous);
                dragging = false;
            }
        });
        // Prevent vertical scrolling when swiping left/right
        this.slider.addEventListener('touchmove', (e) => {
            if (!dragging)
                return;
            let x = e.touches[0].clientX - touchStartX;
            if (Math.abs(x) > 10) {
                e.preventDefault();
                e.returnValue = false;
                return false;
            }
        }, { passive: false });
    }
    triggerTransition(slide) {
        if (this.currentIndex == slide)
            return;
        if (this.itemsCount == 1)
            return;
        if (this.isAnimating)
            return;
        this.setSlide(slide);
        this.slide();
        this.updateIndicator();
    }
    setSlide(slide) {
        this.lastIndex = this.currentIndex;
        // Index of slide
        if (typeof slide === 'number') {
            this.currentIndex = slide;
            if (slide > this.lastIndex) {
                this.slidingDirection = Slide.Next;
            }
            else {
                this.slidingDirection = Slide.Previous;
            }
            return;
        }
        // Next or previous slide
        if (slide == Slide.Previous) {
            if (this.currentIndex == 0) {
                this.currentIndex = this.itemsCount - 1;
            }
            else {
                this.currentIndex--;
            }
            this.slidingDirection = Slide.Previous;
        }
        else {
            if (this.currentIndex == (this.itemsCount - 1)) {
                this.currentIndex = 0;
            }
            else {
                this.currentIndex++;
            }
            this.slidingDirection = Slide.Next;
        }
        return;
    }
    /**
     * Slide animation
     */
    slide() {
        let slideOutClass = 'carousel__item--slide-out-left';
        let slideInClass = 'carousel__item--slide-in-right';
        if (this.slidingDirection == Slide.Previous) {
            slideOutClass = 'carousel__item--slide-out-right';
            slideInClass = 'carousel__item--slide-in-left';
        }
        this.isAnimating = true;
        this.items[this.lastIndex].classList.add(slideOutClass);
        this.items[this.currentIndex].classList.add('carousel__item--active');
        this.items[this.currentIndex].classList.add(slideInClass);
        this.items[this.currentIndex].classList.add('carousel__item--is-sliding');
        // Trigger layout for "slide-in" classes
        window.getComputedStyle(this.items[this.currentIndex]).transform;
        this.items[this.currentIndex].classList.remove(slideInClass);
        this.items[this.currentIndex].classList.add('carousel__item--active');
        // Hold values for timeout
        let lastIndex = this.lastIndex;
        let currentIndex = this.currentIndex;
        setTimeout(() => {
            this.items[lastIndex].classList.remove('carousel__item--active');
            this.items[lastIndex].classList.remove(slideOutClass);
            this.items[currentIndex].classList.remove('carousel__item--is-sliding');
            this.isAnimating = false;
        }, Carousel.ANIMATION_DURATION);
    }
    /**
     * Transition without animation
     */
    // private transit(slide?: Slide) {
    //     this.items[this.currentIndex].classList.add('carousel__item--active');
    //     this.items[this.lastIndex].classList.remove('carousel__item--active');
    // }
    updateIndicator() {
        if (!this.indicators.length)
            return;
        this.indicators[this.lastIndex].classList.remove('carousel__indicator--active');
        this.indicators[this.currentIndex].classList.add('carousel__indicator--active');
    }
    toggleAutoSliding() {
        if (this.isAnimating)
            return;
        if (this.doesAutoSliding) {
            this.stopAutoSliding();
        }
        else {
            this.startAutoSliding();
        }
    }
    startAutoSliding() {
        this.doesAutoSliding = true;
        this.slider.classList.add('carousel--sliding');
        this.slider.classList.add('js-carousel--auto-slide');
        this.autoSlidingTimer = window.setInterval(() => {
            this.triggerTransition(Slide.Next);
        }, Carousel.AUTO_SLIDING_TIMER);
    }
    stopAutoSliding() {
        this.doesAutoSliding = false;
        this.slider.classList.remove('carousel--sliding');
        this.slider.classList.remove('js-carousel--auto-slide');
        window.clearTimeout(this.autoSlidingTimer);
    }
    handleHiddenState() {
        this.didAutoSliding = this.doesAutoSliding;
        this.stopAutoSliding();
    }
    handleVisibleState() {
        // Only start auto sliding if auto sliding was active before the page was hidden
        if (this.didAutoSliding) {
            this.startAutoSliding();
        }
    }
}
export function initCarousels() {
    const carousels = document.querySelectorAll(':not(.edit-mode) .js-carousel');
    for (let i = 0; i < carousels.length; i++) {
        new Carousel(carousels[i]);
    }
}
