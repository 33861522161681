export class Parallax {
    constructor(element) {
        this.window = window;
        this.windowHeight = this.window.innerHeight;
        this.scrolled = 0;
        this.scrollStart = -1;
        this.element = element;
        this.image = this.element.querySelector('img');
        if (!this.image)
            return;
        this.image.style.width = "100%";
        this.setSizeData();
        this.window.addEventListener('scroll', () => {
            this.updateParallax();
        });
        this.image.addEventListener('load', () => {
            this.setSizeData();
            this.updateParallax();
        });
        this.window.addEventListener('resize', () => {
            this.setSizeData();
            this.updateParallax();
        });
    }
    setSizeData() {
        this.windowHeight = this.window.innerHeight;
        this.scrolled = 0;
        this.imageHeight = this.image.clientHeight;
        this.containerHeight = this.element.clientHeight;
        this.parallaxDist = this.imageHeight - this.containerHeight;
        this.scrollStart = -1;
        this.bottom = this.element.offsetTop + this.containerHeight;
        this.top = this.element.offsetTop;
    }
    updateParallax() {
        let scrollTop = this.window.scrollY;
        if ((this.bottom > scrollTop) && (this.top < (scrollTop + this.windowHeight))) {
            if (scrollTop > 0 && this.scrollStart == -1) {
                this.scrollStart = scrollTop;
            }
            this.scrolled = (this.parallaxDist) * 1 / (this.windowHeight + this.containerHeight) * (this.scrollStart - scrollTop - 80);
            this.image.style.transform = 'translateY(' + this.scrolled + 'px)';
        }
    }
}
export function initParallax() {
    const parallax = document.querySelectorAll(".parallax");
    for (let i = 0; i < parallax.length; i++) {
        new Parallax(parallax.item(i));
    }
}
