import { isTabletViewport, isDesktopViewport } from './Utils';
export class TeaserSlider {
    constructor(slider) {
        this.currentIndex = 0;
        this.currentPosition = 0;
        this.itemsCount = 1;
        this.visibleItems = 1;
        this.slider = slider;
        this.items = this.slider.querySelectorAll('.teaser-slider__item');
        this.itemsWrapper = this.slider.querySelector('.teaser-slider__items-wrapper');
        this.itemsCount = this.items.length;
        this.btnForward = this.slider.querySelector('.teaser-slider__control-forward');
        this.btnBackward = this.slider.querySelector('.teaser-slider__control-backward');
        this.setVisibleItems();
        this.updateDom();
        this.initListeners();
    }
    updateDom() {
        this.btnBackward.style.display = this.currentIndex == 0 ? 'none' : 'block';
        this.btnForward.style.display = this.currentIndex >= this.itemsCount - this.visibleItems ? 'none' : 'block';
        this.updateTabIndex();
    }
    initListeners() {
        this.btnForward.addEventListener('click', (e) => {
            e.preventDefault();
            this.currentIndex++;
            this.currentPosition -= 100 / this.visibleItems;
            this.triggerTransition();
        });
        this.btnBackward.addEventListener('click', (e) => {
            e.preventDefault();
            this.currentIndex--;
            this.currentPosition += 100 / this.visibleItems;
            this.triggerTransition();
        });
        window.addEventListener('resize', () => {
            this.currentPosition = 0;
            this.currentIndex = 0;
            this.setVisibleItems();
            this.triggerTransition();
            this.updateDom();
        });
    }
    setVisibleItems() {
        if (isTabletViewport()) {
            this.visibleItems = 2;
        }
        else if (isDesktopViewport()) {
            this.visibleItems = 3;
        }
        else {
            this.visibleItems = 1;
        }
    }
    triggerTransition() {
        this.itemsWrapper.style.transform = 'translateX(' + this.currentPosition + '%)';
        this.updateDom();
    }
    updateTabIndex() {
        for (let i = 0; i < this.items.length; i++) {
            if (i < this.currentIndex + this.visibleItems && i >= this.currentIndex) {
                this.items.item(i).querySelector('.teaser').removeAttribute('tabIndex');
            }
            else {
                this.items.item(i).querySelector('.teaser').setAttribute('tabIndex', '-1');
            }
        }
    }
}
export function initTeaserSliders() {
    const sliders = document.querySelectorAll(".teaser-slider");
    for (var i = 0; i < sliders.length; i++) {
        new TeaserSlider(sliders.item(i));
    }
}
