import { load, post } from "./Utils";
export class LikesCounter {
    constructor() {
        this.likeCounterBtn = document.querySelector("#likes-counter");
        this.serverbaseUrl = this.likeCounterBtn.dataset.url;
        this.uuid = document.querySelector('body').dataset.uuid;
        this.likeCounterText = document.querySelector('.likes-counter__txt');
        this.lang = document.documentElement.lang;
        this.labels = {
            en: {
                peopleSingular: "person",
                peoplePlural: "people",
                likeArticleSingular: "likes this post.",
                likeArticlePlural: "like this post."
            },
            de: {
                peopleSingular: "Person",
                peoplePlural: "Personen",
                likeArticleSingular: "gefällt dieser Beitrag.",
                likeArticlePlural: "gefällt dieser Beitrag."
            }
        };
        if (this.uuid.length) {
            this.getLikes(this.uuid);
        }
        this.likeCounterBtn.addEventListener('click', (event) => {
            event.preventDefault();
            this.likePage(this.uuid);
        });
        if (this.hasAlreadyLiked(this.uuid)) {
            this.disableLikeButton();
        }
    }
    likePage(pageId) {
        if (!this.hasAlreadyLiked(pageId)) {
            post(this.serverbaseUrl + "/like?p=" + pageId, (xhr) => {
                this.likes = JSON.parse(xhr.response);
                this.showLikesCount();
                let likedPages = this.getLikedPages();
                likedPages.push(pageId);
                localStorage.setItem('liked-pages', JSON.stringify(likedPages));
            }, null, { 'X-XSRF-TOKEN': this.token });
            this.disableLikeButton();
        }
    }
    getLikes(pageId) {
        load(this.serverbaseUrl + "?p=" + pageId, (xhr) => {
            const data = JSON.parse(xhr.response);
            this.likes = data.likes;
            this.token = data.xtoken;
            if (this.likes <= 0) {
                this.hideText();
            }
            else {
                this.showLikesCount();
            }
        }, () => {
            this.hideText();
        });
    }
    hideText() {
        this.likeCounterText.style.opacity = '0';
    }
    showLikesCount() {
        if (this.likes <= 0)
            return;
        let spanElem = document.createElement("span");
        spanElem.classList.add('likes-counter__number');
        this.likeCounterText.innerHTML = "";
        if (this.likes == 1) {
            spanElem.innerHTML = this.likes.toString() + " " + this.labels[this.lang].peopleSingular;
            this.likeCounterText.appendChild(spanElem);
            this.likeCounterText.innerHTML += " " + this.labels[this.lang].likeArticleSingular;
        }
        else {
            spanElem.innerHTML = this.likes.toString() + " " + this.labels[this.lang].peoplePlural;
            this.likeCounterText.appendChild(spanElem);
            this.likeCounterText.innerHTML += " " + this.labels[this.lang].likeArticlePlural;
        }
        this.likeCounterText.style.opacity = '';
    }
    getLikedPages() {
        let likedPages = localStorage.getItem('liked-pages');
        return likedPages ? JSON.parse(likedPages) : [];
    }
    hasAlreadyLiked(pageId) {
        let likedPages = this.getLikedPages();
        return likedPages.indexOf(pageId) > -1;
    }
    disableLikeButton() {
        this.likeCounterBtn.classList.add('button--primary-disabled');
    }
}
export function initLikesCounter() {
    if (document.querySelector('#likes-counter')) {
        new LikesCounter();
    }
}
