export class OebbAutocomplete {
    constructor(input) {
        this.isListOpen = false;
        this.activeElementIndex = -1;
        this.randId = Math.random().toString(36).substr(2, 6);
        this.data = [];
        this.input = input;
        this.initDom();
        this.initListeners();
    }
    initDom() {
        this.input.setAttribute('autocomplete', 'off');
        this.template = defaultTemplate;
        this.activeCallback = defaultActive;
        this.selectCallback = defaultSelect;
        this.element = document.createElement('div');
        this.element.classList.add('oebb-autocomplete-wrapper');
        this.element.style.position = 'relative';
        this.input.parentNode.insertBefore(this.element, this.input);
        this.element.appendChild(this.input);
        this.list = document.createElement('ul');
        this.list.classList.add('oebb-autocomplete-list');
        this.list.classList.add('js-oebb-autocomplete-list');
        this.list.style.display = 'none';
        this.element.appendChild(this.list);
        this.addAriaAttributesOnElement();
        this.addAriaAttributesOnInput();
        this.addAriaAttributesOnList();
    }
    addAriaAttributesOnElement() {
        this.element.setAttribute('role', 'combobox');
        this.element.setAttribute('aria-expanded', 'false');
        this.element.setAttribute('aria-haspopup', 'listbox');
        this.element.setAttribute('aria-owns', 'oebb-autocomplete-list-' + this.randId);
    }
    addAriaAttributesOnInput() {
        this.input.setAttribute('role', 'combobox');
        this.input.setAttribute('aria-activedescendant', '');
        this.input.setAttribute('aria-autocomplete', 'list');
        this.input.setAttribute('aria-controls', 'oebb-autocomplete-list-' + this.randId);
    }
    addAriaAttributesOnList() {
        const label = document.querySelector(`[for="${this.input.id}"]`);
        if (!label)
            return;
        label.setAttribute('id', 'oebb-autocomplete-label-' + this.randId);
        this.list.setAttribute('id', 'oebb-autocomplete-list-' + this.randId);
        this.list.setAttribute('role', 'listbox');
        this.list.setAttribute('aria-labelledby', 'oebb-autocomplete-label-' + this.randId);
    }
    refreshAriaAttributesOnItems() {
        if (this.items.length == 0)
            return;
        for (let i = 0; i < this.items.length; i++) {
            this.items[i].setAttribute('id', 'oebb-autocomplete-item-' + i);
            this.items[i].setAttribute('role', 'option');
            this.items[i].setAttribute('aria-selected', 'false');
        }
    }
    refreshAriaAttributesOnElement() {
        if (this.isListOpen) {
            this.element.setAttribute('aria-expanded', 'true');
        }
        else {
            this.element.setAttribute('aria-expanded', 'false');
        }
    }
    refreshAriaAttributesOnInput() {
        if (this.activeElementIndex > -1) {
            this.input.setAttribute('aria-activedescendant', 'oebb-autocomplete-item-' + this.activeElementIndex);
        }
        else {
            this.input.setAttribute('aria-activedescendant', '');
        }
    }
    toggleAriaAttributeSelected(i) {
        if (i < 0)
            return;
        this.input.setAttribute('aria-activedescendant', 'oebb-autocomplete-item-' + i);
        const selected = this.items[i].getAttribute('aria-selected');
        if (selected === null || selected === 'false') {
            this.items[i].setAttribute('aria-selected', 'true');
        }
        else {
            this.items[i].setAttribute('aria-selected', 'false');
        }
    }
    initListeners() {
        this.input.addEventListener('keydown', (e) => this.handleKeydown(e), false);
        this.input.addEventListener('keyup', (e) => this.handleKeyup(e), false);
        this.input.addEventListener('blur', () => this.hideList(), false);
    }
    withTemplate(template) {
        this.template = template;
        return this;
    }
    onKeyup(keyupCallback) {
        this.keyupCallback = keyupCallback;
        return this;
    }
    onKeydown(keydownCallback) {
        this.keydownCallback = keydownCallback;
        return this;
    }
    onItemActive(activeCallback) {
        this.activeCallback = activeCallback;
        return this;
    }
    onItemSelect(selectCallback) {
        this.selectCallback = selectCallback;
        return this;
    }
    setData(data) {
        this.data = data;
    }
    getData() {
        return this.data;
    }
    setValue(value) {
        this.value = value;
        this.input.value = value;
    }
    addValue(value) {
        let valueParts = this.input.value.split(/\s+/);
        valueParts.pop();
        valueParts.push(value);
        this.value = valueParts.join(' ');
        ;
        this.input.value = this.value;
    }
    getValue() {
        return this.input.value;
    }
    getOriginalValue() {
        return this.originalValue;
    }
    getItems() {
        return this.items;
    }
    handleKeydown(e) {
        this.toggleAriaAttributeSelected(this.activeElementIndex);
        if (this.activeElementIndex == -1)
            this.originalValue = this.getValue();
        switch (e.keyCode) {
            case 13: // Enter
                if (this.activeElementIndex > -1)
                    this.selectCallback(this.activeElementIndex, e);
                this.hideList();
                break;
            case 27: // ESC
                this.hideList();
                this.setValue(this.originalValue);
                break;
            case 38: // Up
                if (this.activeElementIndex > -1)
                    e.preventDefault();
                // Any element
                if (this.activeElementIndex > -1) {
                    this.items[this.activeElementIndex].classList.remove('oebb-autocomplete-item--active');
                    this.activeElementIndex--;
                }
                // Any element, still not first element
                if (this.activeElementIndex > -1)
                    this.items[this.activeElementIndex].classList.add('oebb-autocomplete-item--active');
                // Scroll to top after first element, usefull if groups are used
                if (this.activeElementIndex == -1)
                    this.list.scrollTop = 0;
                // If on element, fire activeCallback
                if (this.activeElementIndex == -1)
                    this.setValue(this.originalValue);
                if (this.activeElementIndex > -1)
                    this.activeCallback(this.activeElementIndex);
                break;
            case 40: // Down
                // List not open, no data
                if (this.data.length == 0)
                    return;
                // List not open
                if (!this.isListOpen && this.data.length > 0) {
                    this.activeElementIndex = -1;
                    this.render();
                    break;
                }
                // Last element
                if ((this.activeElementIndex + 1) == this.items.length)
                    break;
                // Any element
                if (this.activeElementIndex > -1)
                    this.items[this.activeElementIndex].classList.remove('oebb-autocomplete-item--active');
                this.activeElementIndex++;
                this.items[this.activeElementIndex].classList.add('oebb-autocomplete-item--active');
                // If on element, fire activeCallback
                if (this.activeElementIndex == -1)
                    this.setValue(this.originalValue);
                if (this.activeElementIndex > -1)
                    this.activeCallback(this.activeElementIndex);
                break;
        }
        this.toggleAriaAttributeSelected(this.activeElementIndex);
        this.refreshAriaAttributesOnElement();
        this.refreshAriaAttributesOnInput();
        if (this.keydownCallback)
            this.keydownCallback(e);
        // No element
        if (this.activeElementIndex == -1)
            return;
        if (e.keyCode == 38 && this.isListOpen && !this.isItemInListView()) {
            const y = this.items[this.activeElementIndex].offsetTop;
            this.list.scrollTop = y;
        }
        if (e.keyCode == 40 && this.isListOpen && !this.isItemInListView()) {
            const y = this.items[this.activeElementIndex].offsetTop - this.list.clientHeight + this.items[this.activeElementIndex].clientHeight;
            this.list.scrollTop = y;
        }
    }
    handleKeyup(e) {
        this.value = this.input.value;
        this.lastKeyCode = e.keyCode;
        if (e.keyCode === 13
            || e.keyCode === 27
            || e.keyCode === 38
            || e.keyCode === 40)
            return;
        this.keyupCallback(e);
        this.render();
    }
    hideList() {
        this.activeElementIndex = -1;
        if (!this.isListOpen)
            return;
        this.list.scrollTop = 0;
        this.list.style.display = 'none';
        this.isListOpen = false;
        this.refreshAriaAttributesOnElement();
        this.refreshAriaAttributesOnInput();
    }
    isItemInListView() {
        const rectList = this.list.getBoundingClientRect();
        const rectItem = this.items[this.activeElementIndex].getBoundingClientRect();
        return (rectItem.top >= rectList.top && rectItem.top <= rectList.top + rectList.height - rectItem.height);
    }
    refreshItemListener() {
        for (let i = 0; i < this.items.length; i++) {
            this.items[i].addEventListener('mousedown', (e) => e.preventDefault(), false); // Prevent list from closing on item click because of "blur" event
            this.items[i].addEventListener('click', (e) => {
                e.stopPropagation();
                this.selectCallback(i, e);
                this.hideList();
                this.input.focus();
            }, false);
        }
    }
    render() {
        this.activeElementIndex = -1;
        this.list.scrollTop = 0;
        if (!this.value || this.value == '')
            return this.hideList();
        if (this.lastKeyCode === 13)
            return; // Prevents panel after submitting form because of suggestions via async request
        let listHtml = ``;
        this.list.innerHTML = ``;
        this.data.forEach((item, i) => {
            listHtml += this.template(item, i);
        });
        this.list.innerHTML = listHtml;
        this.list.style.display = 'block';
        this.isListOpen = true;
        this.items = this.list.querySelectorAll('.js-oebb-autocomplete-item');
        this.refreshItemListener();
        this.refreshAriaAttributesOnElement();
        this.refreshAriaAttributesOnItems();
    }
}
var FilterMode;
(function (FilterMode) {
    FilterMode["StartsWith"] = "STARTS_WITH";
    FilterMode["Contains"] = "CONTAINS";
})(FilterMode || (FilterMode = {}));
;
const defaultTemplate = function (item) {
    const valueParts = this.input.value.split(/\s+/);
    const valueLast = valueParts.pop().toString();
    if (item.toLowerCase().indexOf(valueLast.toLowerCase()) === 0) {
        return `<li class="oebb-autocomplete-item js-oebb-autocomplete-item">${item.substring(0, valueLast.length) + `<strong>` + item.substring(valueLast.length)}</strong></li>`;
    }
    return `<li class="oebb-autocomplete-item js-oebb-autocomplete-item">${item}</strong></li>`;
};
const defaultActive = function () {
    this.addValue(this.items[this.activeElementIndex].textContent);
};
const defaultSelect = function (index) {
    this.addValue(this.getData()[index]);
};
export function createOebbAutocompleteFromStringArray(input, rawData, filterMode = FilterMode.StartsWith) {
    return new OebbAutocomplete(input)
        .withTemplate(defaultTemplate)
        .onKeyup(function () {
        const value = this.getValue().split(/\s+/).pop();
        if (!value.trim())
            return;
        let data = [];
        if (filterMode === FilterMode.Contains) {
            data = rawData.filter((option) => {
                return (option.toLowerCase().indexOf(value.toLowerCase()) > -1);
            });
        }
        else {
            data = rawData.filter((option) => {
                return (option.toLowerCase().indexOf(value.toLowerCase()) === 0);
            });
        }
        this.setData(data);
    })
        .onItemSelect(defaultSelect);
}
export function createOebbAutocompleteFromRemoteStringArray(input, url, options = {}) {
    options.paramKey = options.paramKey || 'q';
    options.suggestionsKey = options.suggestionsKey || 'suggestions';
    options.debounceTiming = options.debounceTiming;
    if (options.debounceTiming !== 0)
        options.debounceTiming = 500;
    const xhr = new XMLHttpRequest();
    const origin = url;
    let debounceTimer;
    return new OebbAutocomplete(input)
        .withTemplate(defaultTemplate)
        .onKeyup(function () {
        const value = this.getValue().split(/\s+/).pop();
        if (!value.trim())
            return;
        (() => {
            if (debounceTimer)
                clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => {
                let url;
                if (origin.indexOf('?') > -1) {
                    url = origin + `&${options.paramKey}=${value}`;
                }
                else {
                    url = origin + `?${options.paramKey}=${value}`;
                }
                xhr.open('GET', encodeURI(url), true);
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                        const result = JSON.parse(xhr.response);
                        this.setData(result[options.termsKey]);
                        this.render();
                    }
                };
                xhr.send(null);
            }, options.debounceTiming);
        })();
    })
        .onItemSelect(defaultSelect);
}
export function createOebbAutocompleteFromRemoteStringArrayWithTopResults(input, url, options = {}) {
    options.paramKey = options.paramKey || 'q';
    options.suggestionsKey = options.suggestionsKey || 'suggestions';
    options.topResultsKey = options.topResultsKey || 'top_results';
    options.debounceTiming = options.debounceTiming;
    if (options.debounceTiming !== 0)
        options.debounceTiming = 500;
    const xhr = new XMLHttpRequest();
    const origin = url;
    let debounceTimer;
    return new OebbAutocomplete(input)
        .withTemplate(function (group, indexGroup) {
        let groupHtml = ``;
        if (typeof group[0] === 'string') {
            groupHtml = `
                    <li class="oebb-autocomplete-group">
                        <span class="oebb-autocomplete-group-label">Vorschläge</span>
                        <ul data-group="${indexGroup}">
                    `;
            group.forEach((item, indexItem) => {
                const valueParts = this.input.value.split(/\s+/);
                const valueLast = valueParts.pop().toString();
                groupHtml += `
                        <li class="oebb-autocomplete-item js-oebb-autocomplete-item" data-item="${indexItem}">
                            ${item.substring(0, valueLast.length) + `<strong>` + item.substring(valueLast.length)}</strong>
                        </li>
                    `;
            });
            groupHtml += `
                        </ul>
                    </li>
                `;
        }
        if (typeof group[0] === 'object') {
            groupHtml = `
                    <li class="oebb-autocomplete-group">
                        <span class="oebb-autocomplete-group-label">Top Results</span>
                        <ul data-group="${indexGroup}">
                `;
            group.forEach((item, indexItem) => {
                groupHtml += `
                        <li class="oebb-autocomplete-item js-oebb-autocomplete-item" data-item="${indexItem}">
                            ${item.title}
                            <span class="oebb-autocomplete-item__type">
                                ${item.url.indexOf('.pdf') > -1 ? 'PDF' : 'Seite'}
                            </span>
                        </li>`;
            });
            groupHtml += `
                        </ul>
                    </li>
                `;
        }
        return groupHtml;
    })
        .onKeyup(function () {
        const value = this.getValue().split(/\s+/).pop();
        if (!value.trim())
            return;
        (() => {
            if (debounceTimer)
                clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => {
                let url;
                if (origin.indexOf('?') > -1) {
                    url = origin + `&${options.paramKey}=${value}`;
                }
                else {
                    url = origin + `?${options.paramKey}=${value}`;
                }
                xhr.open('GET', encodeURI(url), true);
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                        const result = JSON.parse(xhr.response);
                        const data = [];
                        if (result[options.topResultsKey])
                            data.push(result[options.topResultsKey]);
                        if (result[options.suggestionsKey])
                            data.push(result[options.suggestionsKey]);
                        this.setData(data);
                        this.render();
                    }
                };
                xhr.send(null);
            }, options.debounceTiming);
        })();
    })
        .onItemActive(function (itemIndex) {
        const itemElement = this.getItems()[itemIndex];
        const group = itemElement.parentNode.dataset.group;
        const index = itemElement.dataset.item;
        const item = this.getData()[group][index];
        if (typeof item === 'string')
            this.addValue(item);
        if (typeof item === 'object')
            this.setValue(this.getOriginalValue());
    })
        .onItemSelect(function (itemIndex) {
        const itemElement = this.getItems()[itemIndex];
        const group = itemElement.parentNode.dataset.group;
        const index = itemElement.dataset.item;
        const item = this.getData()[group][index];
        if (typeof item === 'string')
            this.addValue(item);
        if (typeof item === 'object')
            window.location.href = item.url;
    });
}
;
export function addOebbautocompleteDefaultStyle(tint = '#e2002a') {
    if (document.querySelector('#js-oebb-autocomplete-styles'))
        return;
    const style = document.createElement('style');
    style.id = 'js-oebb-autocomplete-styles';
    style.innerHTML = `
        .oebb-autocomplete-wrapper {
            position: relative;
        }

        .oebb-autocomplete {}

        .oebb-autocomplete-list {
            position: absolute;
            z-index: 10;

            width: 100%;
            max-height: 240px;
            margin: 0;
            padding: 0;
            overflow-x: hidden;
            overflow-y: auto;

            background: #ffffff;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19);
            list-style: none;

            font-size: 18px;
        }

        .oebb-autocomplete-list ul {
            margin: 0;
            padding: 0;

            list-style: none;
        }

        .oebb-autocomplete-group-label,
        .oebb-autocomplete-item {
            display: inline-block;

            width: 100%;
            height: 40px;
            padding: 6px 15px;
            box-sizing: border-box;

            line-height: 28px;
        }

        .oebb-autocomplete-group-label {
            background: #efefef;

            text-transform: uppercase;
            font-weight: bold;
        }

        .oebb-autocomplete-item {
            border-bottom: 1px solid #efefef;
            cursor: pointer;
        }

        .oebb-autocomplete-item:hover,
        .oebb-autocomplete-item--active {
            color: ${tint};
        }

        .oebb-autocomplete-item__type {
            float: right;

            display: block;
            padding: 5px 12px;
            margin-left: 25px;

            border: 1px solid currentColor;

            font-size: 12px;
            line-height: 1em;
            letter-spacing: 1px;
            text-transform: uppercase;
        }
    `;
    document.head.appendChild(style);
}
