export class ScrollEvents {
    constructor(element) {
        this.classScrollUp = 'scroll--up';
        this.classScrollDown = 'scroll--down';
        this.lastScroll = 0;
        this.element = element;
        window.addEventListener("scroll", () => {
            var currentScroll = window.pageYOffset;
            if (currentScroll <= 0) {
                this.element.classList.remove(this.classScrollUp);
                return;
            }
            if (currentScroll > this.lastScroll && !this.element.classList.contains(this.classScrollDown) && this.element.getBoundingClientRect().top < currentScroll - window.innerHeight) {
                // down
                this.element.classList.remove(this.classScrollUp);
                this.element.classList.add(this.classScrollDown);
            }
            else if (currentScroll < this.lastScroll && this.element.classList.contains(this.classScrollDown) && this.element.getBoundingClientRect().top < currentScroll - window.innerHeight) {
                // up
                this.element.classList.remove(this.classScrollDown);
                this.element.classList.add(this.classScrollUp);
            }
            this.lastScroll = currentScroll;
        });
    }
}
export function initScrollEvents() {
    const scrollEvents = document.querySelectorAll('.scroll-events');
    for (let i = 0; i < scrollEvents.length; i++) {
        new ScrollEvents(scrollEvents[i]);
    }
}
