import animateScrollTo from 'animated-scroll-to';
import { formatDate, shortenText, createElement, getOffset } from "./Utils";
export class Playlist {
    constructor(element, apiKey) {
        this.lang = document.documentElement.lang;
        this.dataUUID = document.querySelector('body').getAttribute('data-uuid');
        this.label = {
            moreButton: this.lang == "de" ? "Mehr anzeigen" : "show more",
            viewVideo: this.lang == "de" ? "Video ansehen" : "Watch video",
            addToCustomPlaylist: this.lang == "de" ? "zur Playlist hinzufügen" : "add to playlist",
            myPlaylist: this.lang == "de" ? "Meine Playlist" : "My Playlist",
            deleteVideo: this.lang == "de" ? 'Video aus meiner Playlist entfernen: ' : 'Remove video from my playlist: ',
            playVideo: this.lang == "de" ? 'Video abspielen' : 'play video'
        };
        this.setApiKey(apiKey);
        this.target = element;
        this.playlistId = this.target.getAttribute('data-playlist');
        this.searchRequestOptions = {
            part: 'snippet, status',
            maxResults: 12,
            playlistId: this.playlistId
        };
    }
    setApiKey(apiKey) {
        setTimeout(() => {
            if (!gapi.client) {
                this.setApiKey(apiKey);
                return;
            }
            gapi.client.setApiKey(apiKey), gapi.client.load("youtube", "v3", () => {
                this.initPlaylist();
            });
        }, 10);
    }
    initPlaylist() {
        this.setupHTML();
        this.listPlaylistItems(this.searchRequestOptions);
        this.listCustomPlaylistItems();
    }
    setupHTML() {
        if (this.videoScreen && this.targetInner)
            return;
        if (!this.videoScreen) {
            this.videoScreen = createElement('div', ['js-playlist__screen']);
            this.target.appendChild(this.videoScreen);
            let videoContainerOuter = createElement('div', ['video-container__outer']);
            this.videoScreen.appendChild(videoContainerOuter);
            this.videoContainer = createElement('div', ['video-container']);
            videoContainerOuter.appendChild(this.videoContainer);
            let youtubeFrame = createElement('iframe', ['video-iframe']);
            youtubeFrame.setAttribute('title', 'Youtube-Video');
            this.videoContainer.appendChild(youtubeFrame);
        }
        if (!this.targetInner) {
            this.targetInner = createElement('div', ['row']);
            this.target.appendChild(this.targetInner);
        }
    }
    listPlaylistItems(playlistItemsRequestOptions) {
        let playlistItemsRequest = gapi.client.youtube.playlistItems.list(playlistItemsRequestOptions);
        playlistItemsRequest.execute((playlistItemsResponse) => {
            this.updateVideoContainer(playlistItemsResponse.items[0].snippet.resourceId.videoId);
            playlistItemsResponse.items.forEach((element) => {
                this.createTeaser(element);
            });
            if (playlistItemsResponse.nextPageToken) {
                this.updateMoreButton(playlistItemsResponse.nextPageToken);
            }
            else {
                if (this.moreButton) {
                    this.moreButton.remove();
                }
            }
        });
    }
    updateVideoContainer(videoId) {
        let frameSrc = "https://www.youtube-nocookie.com/embed/" + videoId + "?autoplay=0&fs=0&showinfo=0";
        let customPlaylist = this.getCustomPlaylist();
        if (customPlaylist.length > 0) {
            if (this.isInCustomPlaylist(videoId)) {
                const index = customPlaylist.indexOf(videoId, 0);
                customPlaylist.splice(index, 1);
            }
            frameSrc += "&playlist=" + customPlaylist.join();
        }
        this.videoContainer.querySelector('iframe').src = frameSrc;
    }
    createTeaser(item) {
        if (item.status.privacyStatus == "private")
            return;
        const videoId = item.snippet.resourceId.videoId;
        let column = createElement('div', ['col-sm-6', 'col-md-4']);
        this.targetInner.appendChild(column);
        // Teaser Link
        let teaser = createElement('a', ['js-playlist__item', 'teaser', 'teaser--standard']);
        teaser.href = "#";
        teaser.setAttribute("id", videoId);
        teaser.addEventListener('click', (e) => {
            e.preventDefault();
            this.updateVideoContainer(videoId);
            animateScrollTo(getOffset(this.videoContainer).top);
        });
        if (this.isInCustomPlaylist(videoId)) {
            teaser.classList.add('js-playlist__item--added');
        }
        column.appendChild(teaser);
        // Teaser Img
        let teaserImageHolder = createElement('div', ['teaser__image-wrapper']);
        teaser.appendChild(teaserImageHolder);
        let teaserImage = createElement('img', ['teaser__image']);
        teaserImage.src = item.snippet.thumbnails.medium.url;
        teaserImage.alt = "";
        teaserImageHolder.appendChild(teaserImage);
        // Teaser Text
        let teaserDescription = createElement('div', ['teaser__desc']);
        teaser.appendChild(teaserDescription);
        let teaserTitle = createElement('h3', ['teaser__headline']);
        teaserTitle.innerHTML = shortenText(item.snippet.title, 40);
        let teaserIntro = createElement('div', ['teaser__intro']);
        teaserIntro.innerHTML = shortenText(item.snippet.description, 90);
        let teaserPublishedAt = createElement('p', ['subtitle', 'teaser__category']);
        teaserPublishedAt.innerHTML = formatDate(new Date(item.snippet.publishedAt));
        let teaserPseudolink = createElement('span', ['teaser__pseudolink']);
        teaserPseudolink.innerHTML = this.label.viewVideo;
        teaserDescription.appendChild(teaserPublishedAt);
        teaserDescription.appendChild(teaserTitle);
        teaserDescription.appendChild(teaserIntro);
        teaserDescription.appendChild(teaserPseudolink);
        // Add-Button
        let teaserAddButton = createElement('div', ['teaser__series', 'js-playlist__add-btn']);
        let teaserAddButtonIcon = createElement('span', ['obb-icon-plus']);
        let teaserAddButtonText = createElement('span', ['teaser__series-hover']);
        teaserAddButtonText.innerHTML = this.label.addToCustomPlaylist;
        teaserAddButton.appendChild(teaserAddButtonIcon);
        teaserAddButton.appendChild(teaserAddButtonText);
        teaserAddButton.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            teaser.classList.add('js-playlist__item--added');
            this.addToCustomPlaylist(item);
        });
        teaserDescription.appendChild(teaserAddButton);
    }
    updateMoreButton(nextPageToken) {
        if (!this.moreButton) {
            this.createMoreButton();
        }
        this.moreButton.setAttribute('data-nextPageToken', nextPageToken);
    }
    createMoreButton() {
        this.moreButton = createElement('a', ['button', 'button--primary', 'js-playlist__more-btn']);
        this.moreButton.href = "#";
        this.moreButton.addEventListener('click', (e) => {
            e.preventDefault();
            const clickedButton = e.target;
            this.getMoreSearchVideos(clickedButton.getAttribute('data-nextPageToken'));
        });
        this.moreButton.innerHTML = this.label.moreButton;
        this.target.appendChild(this.moreButton);
    }
    getMoreSearchVideos(nextPageToken) {
        this.searchRequestOptions.pageToken = nextPageToken;
        this.listPlaylistItems(this.searchRequestOptions);
    }
    // Custom Playlist
    listCustomPlaylistItems() {
        let customPlaylist = this.getCustomPlaylist();
        customPlaylist.forEach((videoId) => {
            const videosRequestOptions = {
                part: 'contentDetails, snippet, status',
                id: videoId,
                maxResults: 1
            };
            const videosRequest = gapi.client.youtube.videos.list(videosRequestOptions);
            videosRequest.execute((videosResponse) => {
                this.createPlaylistItem(videosResponse.items[0]);
            });
        });
    }
    createPlaylistItem(item) {
        if (!this.customPlaylist) {
            this.setupCustomPlaylistHTML();
        }
        const videoId = item.snippet.resourceId ? item.snippet.resourceId.videoId : item.id;
        let wrapper = createElement('div', ['custom-playlist__item']);
        this.customPlaylist.appendChild(wrapper);
        // PlaylistItem
        let playlistItem = createElement('a', ['custom-playlist__content']);
        playlistItem.href = '#';
        playlistItem.setAttribute('aria-label', this.label.playVideo);
        playlistItem.addEventListener('click', (e) => {
            e.preventDefault();
            this.updateVideoContainer(videoId);
        });
        wrapper.appendChild(playlistItem);
        let playlistItemThumb = createElement('img', ['custom-playlist__content-thumb']);
        playlistItemThumb.src = item.snippet.thumbnails.medium.url;
        playlistItemThumb.alt = "";
        playlistItem.appendChild(playlistItemThumb);
        let playlistItemContent = createElement('div');
        playlistItem.appendChild(playlistItemContent);
        let playlistItemTitle = createElement('div', ['custom-playlist__content-title']);
        playlistItemTitle.innerHTML = shortenText(item.snippet.title, 40);
        playlistItemContent.appendChild(playlistItemTitle);
        let playlistItemChannel = createElement('div', ['custom-playlist__content-channel']);
        playlistItemChannel.innerHTML = item.snippet.channelTitle;
        playlistItemContent.appendChild(playlistItemChannel);
        // Remove Button
        let removeFromPlaylist = createElement('a', ['custom-playlist__remove-btn', 'obb-icon-minus']);
        removeFromPlaylist.href = "#";
        removeFromPlaylist.setAttribute('aria-label', this.label.deleteVideo + shortenText(item.snippet.title, 40));
        removeFromPlaylist.addEventListener('click', (e) => {
            e.preventDefault();
            this.removeFromPlaylist(videoId, wrapper);
        });
        wrapper.appendChild(removeFromPlaylist);
    }
    setupCustomPlaylistHTML() {
        const container = createElement('div', ['custom-playlist']);
        this.videoScreen.appendChild(container);
        this.customPlaylist = createElement('div', ['custom-playlist__items']);
        container.appendChild(this.customPlaylist);
        let headline = createElement('h2', ['custom-playlist__headline']);
        headline.innerHTML = this.label.myPlaylist;
        this.customPlaylist.appendChild(headline);
    }
    getCustomPlaylist() {
        let customPlaylist = localStorage.getItem('yt-' + this.dataUUID);
        return customPlaylist ? JSON.parse(customPlaylist) : [];
    }
    isInCustomPlaylist(videoId) {
        return this.getCustomPlaylist().indexOf(videoId) > -1;
    }
    addToCustomPlaylist(item) {
        let customPlaylist = this.getCustomPlaylist(), videoId = item.snippet.resourceId.videoId;
        if (!this.isInCustomPlaylist(videoId)) {
            customPlaylist.push(videoId);
            localStorage.setItem('yt-' + this.dataUUID, JSON.stringify(customPlaylist));
            this.createPlaylistItem(item);
        }
    }
    removeFromPlaylist(videoId, playlistHTMLItem) {
        const customPlaylist = this.getCustomPlaylist(), index = customPlaylist.indexOf(videoId, 0);
        if (index > -1) {
            customPlaylist.splice(index, 1);
            localStorage.setItem('yt-' + this.dataUUID, JSON.stringify(customPlaylist));
        }
        let videoTeaser = document.getElementById(videoId);
        videoTeaser.classList.remove('js-playlist__item--added');
        playlistHTMLItem.parentNode.removeChild(playlistHTMLItem);
    }
    static addScript(onLoadCallback) {
        if (!document.querySelector('js-youtube-api')) {
            let scriptElement = document.createElement('script');
            scriptElement.async = true;
            scriptElement.defer = true;
            scriptElement.src = 'https://apis.google.com/js/client.js';
            scriptElement.classList.add('js-youtube-api');
            scriptElement.addEventListener('load', () => {
                onLoadCallback();
            });
            document.body.appendChild(scriptElement);
        }
    }
}
export function initPlaylist() {
    const playlists = document.querySelectorAll(".js-playlist");
    if (playlists.length > 0) {
        Playlist.addScript(() => {
            for (let i = 0; i < playlists.length; i++) {
                new Playlist(playlists.item(i), playlists.item(i).getAttribute('data-api-key'));
            }
        });
    }
}
