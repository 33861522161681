import { Observer } from './Observer';
import { generateTeaser } from './Utils';
import { lazyLoadImages } from './lazy-load-images';
export class Filter extends Observer {
    constructor() {
        super();
        this.html = document.querySelector('html');
        this.filter = document.querySelector('.js-filter');
        this.toggleFilterButton = document.querySelector('.js-toggle-filter');
        this.optionsPanel = document.querySelector('.js-filter-options');
        this.submitFilterButton = document.querySelector('.js-submit-filter');
        this.submitFilterButtonText = this.submitFilterButton.textContent;
        this.url = this.filter.getAttribute('data-url');
        this.template = document.querySelector('#teaser-template');
        this.originalView = document.querySelector('.js-main').innerHTML;
        this.isOpen = false;
        this.activeFilters = [];
        this.hasActiveFilters = Boolean(this.activeFilters.length);
        this.articles = [];
        this.hasArticles = Boolean(this.articles.length);
        this.activeRequests = 0;
        this.initListeners();
    }
    initListeners() {
        this.toggleFilterButton.addEventListener('click', () => this.toggleFilter());
        this.submitFilterButton.addEventListener('click', () => this.submitFilter());
        const categoryLinks = document.querySelectorAll('.js-select-filter-parameter');
        for (let i = 0; i < categoryLinks.length; i++) {
            categoryLinks[i].addEventListener('click', (e) => { e.preventDefault(); this.selectFilterParameter(e); });
        }
    }
    toggleFilter() {
        this.notifyObservers();
        if (this.isOpen) {
            this.closeFilter();
            if (this.hasArticles) {
                this.renderFilterOverview();
                this.renderArticles();
            }
        }
        else {
            this.openFilter();
        }
    }
    openFilter() {
        this.optionsPanel.style.height = this.optionsPanel.scrollHeight + 'px';
        this.html.classList.add('dialog-open');
        this.filter.classList.add('filter--open');
        this.isOpen = true;
    }
    closeFilter() {
        this.optionsPanel.style.height = '0px';
        this.html.classList.remove('dialog-open');
        this.filter.classList.remove('filter--open');
        this.isOpen = false;
    }
    selectFilterParameter(e) {
        const link = e.target;
        const id = link.getAttribute('data-category-id');
        const type = link.getAttribute('data-category-type');
        const value = link.textContent;
        let foundIndex = this.findFilterParameterById(id);
        if (foundIndex > -1) {
            this.activeFilters.splice(foundIndex, 1);
            link.classList.remove('filter-parameter--active');
        }
        else {
            this.activeFilters.push({ 'id': id, 'type': type, 'value': value });
            link.classList.add('filter-parameter--active');
        }
        this.hasActiveFilters = Boolean(this.activeFilters.length);
        this.toggleFilterButton.disabled = true;
        this.submitFilterButton.disabled = true;
        this.submitFilterButton.textContent = this.submitFilterButton.getAttribute('data-loading');
        this.submitFilterButton.classList.add('button--primary-disabled');
        // Debounce requests
        (() => {
            if (this.debounceTimer)
                clearTimeout(this.debounceTimer);
            this.debounceTimer = window.setTimeout(() => {
                this.getResults(() => {
                    if (this.activeRequests == 0) {
                        this.toggleFilterButton.disabled = false;
                        this.renderResultCount();
                        if (!this.hasActiveFilters) {
                            this.resetFilter();
                        }
                    }
                });
            }, 500);
        })();
    }
    submitFilter() {
        if (!this.hasActiveFilters && !this.hasArticles) {
            return this.closeFilter();
        }
        if (!this.hasActiveFilters && this.hasArticles) {
            this.resetFilter();
        }
        this.renderFilterOverview();
        this.renderArticles();
        return this.closeFilter();
    }
    removeFilterParameter(e) {
        let button = e.target;
        let categoryId = button.getAttribute('data-category-id');
        let foundIndex = this.findFilterParameterById(categoryId);
        if (foundIndex > -1) {
            this.activeFilters.splice(foundIndex, 1);
        }
        document.querySelector(`.js-select-filter-parameter[data-category-id="${categoryId}"]`).classList.remove('filter-parameter--active');
        this.hasActiveFilters = Boolean(this.activeFilters.length);
        if (this.hasActiveFilters) {
            this.renderFilterOverview();
            this.getResults(() => {
                this.renderArticles();
                this.renderResultCount();
            });
        }
        else {
            this.resetFilter();
        }
    }
    resetFilter() {
        this.activeFilters = [];
        this.hasActiveFilters = false;
        this.articles = [];
        this.hasArticles = false;
        Array.prototype.map.call(document.querySelectorAll('.js-select-filter-parameter'), function (el) {
            el.classList.remove('filter-parameter--active');
        });
        this.submitFilterButton.textContent = this.submitFilterButtonText;
        document.querySelector('.js-main').innerHTML = this.originalView;
        lazyLoadImages();
    }
    findFilterParameterById(id) {
        let foundIndex = -1;
        this.activeFilters.forEach((filter, i) => {
            if (filter.id == id)
                foundIndex = i;
        });
        return foundIndex;
    }
    getResults(onCompleted) {
        let url = this.url;
        let xhr = new XMLHttpRequest();
        this.activeRequests++;
        if (this.hasActiveFilters) {
            url += '?categories=' + this.activeFilters.map((filter) => filter.id).join('|');
            url += '&lang=' + this.filter.getAttribute('data-lang');
        }
        xhr.open('GET', encodeURI(url), true);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                this.articles = JSON.parse(xhr.response).results;
                this.hasArticles = Boolean(this.articles.length);
                this.activeRequests--;
                if (onCompleted)
                    onCompleted(this.articles);
            }
        };
        xhr.send(null);
    }
    renderResultCount() {
        let count = this.articles.length;
        this.submitFilterButton.innerHTML = count == 1
            ? count + ' ' + this.submitFilterButton.getAttribute('data-result-singular')
            : count + ' ' + this.submitFilterButton.getAttribute('data-result-plural');
        if (this.hasActiveFilters && !this.hasArticles) {
            this.submitFilterButton.innerHTML = `0 ${this.filter.getAttribute('data-result-plural')}`;
            this.submitFilterButton.disabled = true;
            this.submitFilterButton.classList.add('button--primary-disabled');
        }
        else {
            this.submitFilterButton.disabled = false;
            this.submitFilterButton.classList.remove('button--primary-disabled');
            // Fixes button render problem in IE, which doesn't show full with and cuts off text
            const activeElement = document.activeElement;
            this.submitFilterButton.focus();
            activeElement.focus();
        }
    }
    renderFilterOverview() {
        const categoryContent = document.querySelector('.js-category-content');
        let filterOverview = document.querySelector('.js-filter-overview');
        if (filterOverview) {
            filterOverview.innerHTML = '';
        }
        else {
            filterOverview = document.createElement('div');
            // Do not use the following two commands as one command, breaks IE >= 10
            filterOverview.classList.add('filter-overview');
            filterOverview.classList.add('js-filter-overview');
        }
        if (!this.hasActiveFilters)
            return;
        let filterOverviewHtml = `
            <ul class="filter-parameter-list filter-parameter-list--overview">
        `;
        this.activeFilters.forEach((filter) => {
            filterOverviewHtml += `
                <li class="filter-parameter-list__item">
                    <span class="filter-parameter filter-parameter--active filter-parameter--removeable">
                        ${filter.value}
                        <button class="filter-parameter__remove js-remove-filter-parameter" data-category-id="${filter.id}">&times;</button>
                    </span>
                </li>
            `;
        });
        filterOverviewHtml += `
            </ul>
        `;
        filterOverviewHtml += `
            <button class="filter-reset js-reset-filter">
                <i class="obb-icon-repeat" aria-hidden="true"></i> `
            + this.filter.getAttribute('data-reset') +
            `</button>
        `;
        filterOverview.innerHTML = filterOverviewHtml;
        let filterRemoveButtons = filterOverview.querySelectorAll('.js-remove-filter-parameter');
        for (let i = 0; i < filterRemoveButtons.length; i++) {
            filterRemoveButtons[i].addEventListener('click', (e) => this.removeFilterParameter(e));
        }
        filterOverview.querySelector('.js-reset-filter').addEventListener('click', () => this.resetFilter());
        categoryContent.parentNode.insertBefore(filterOverview, categoryContent);
    }
    renderArticles() {
        const categoryContent = document.querySelector('.js-category-content');
        let articlesHtml = '';
        categoryContent.innerHTML = articlesHtml;
        this.template = document.querySelector('#teaser-template'); // This line prevents a problem in IE >= 10
        let count = this.articles.length;
        let heading = count == 1
            ? count + ' ' + this.filter.getAttribute('data-result-singular')
            : count + ' ' + this.filter.getAttribute('data-result-plural');
        articlesHtml += `
            <h1>${heading}</h1>

            <div class="row">
        `;
        this.articles.forEach((teaser) => {
            articlesHtml += generateTeaser(this.template, {
                'href': teaser.href,
                'imageSrc': teaser.hero.link ? teaser.hero.link : '',
                'imageSrcSet': teaser.landingPage && teaser.hero.link ? teaser.hero.link + ', ' + teaser.hero.link + "-2x.png 2x" : teaser.hero.linkSrcSet,
                'imageLazySrc': teaser.hero.linkLazy ? teaser.hero.linkLazy : '',
                'imageAlt': teaser.hero.alt ? teaser.hero.alt : '',
                'imageTitle': teaser.hero.creditTextFull ? teaser.hero.creditTextFull : '',
                'category': teaser.categoryTitle,
                'title': teaser.title,
                'leadin': teaser.leadin
            });
        });
        articlesHtml += `
            </div>
        `;
        categoryContent.innerHTML = articlesHtml;
        lazyLoadImages();
        this.renderCredits();
    }
    renderCredits() {
        if (!document.querySelector('.js-image-credits'))
            return;
        const list = document.querySelector('.js-image-credits-list');
        const credits = [];
        this.articles.forEach((teaser) => {
            if (teaser.hero.creditTextFull) {
                credits.push(teaser.hero.creditTextFull);
            }
        });
        let listContent = '';
        credits.forEach(function (credit, i) {
            const isLast = (i === (credits.length - 1));
            const creditOut = credit.indexOf('©') > 0 ? credit.replace('©', '-') : credit;
            listContent += `
                <li>${creditOut.trim() + (!isLast ? ';' : '')}</li>
            `;
        });
        list.innerHTML = listContent;
    }
    notify() {
        this.closeFilter();
    }
}
export function initFilter(menu, searchToggler) {
    if (document.querySelector('.js-filter')) {
        const filter = new Filter();
        menu.addObserver(filter);
        if (searchToggler)
            searchToggler.addObserver(filter);
        filter.addObserver(menu);
        if (searchToggler)
            filter.addObserver(searchToggler);
    }
}
