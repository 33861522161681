export function initColcade(selector) {
    if (document.querySelector(selector)) {
        return new Colcade(document.querySelector(selector), {
            columns: '.js-colcade-column',
            items: '.js-colcade-item'
        });
    }
}
export function initColcades() {
    const colcadeGrids = document.querySelectorAll('.js-colcade');
    for (let i = 0; i < colcadeGrids.length; i++) {
        const ref = new Colcade(colcadeGrids[i], {
            columns: '.js-colcade-column',
            items: '.js-colcade-item'
        });
        colcadeGrids[i].ref = ref;
    }
}
