import { load } from "./Utils";
export class MostViewed {
    constructor(mostViewedContainer) {
        this.mostViewedContainer = mostViewedContainer;
        this.resultsLimit = this.mostViewedContainer.getAttribute('data-limit');
        this.serviceUrl = this.mostViewedContainer.getAttribute('data-service-url');
        console.log(this.serviceUrl);
        if (this.resultsLimit.length) {
            this.getData();
        }
    }
    getData() {
        load(this.serviceUrl + "?limit=" + this.resultsLimit, (xhr) => {
            const data = JSON.parse(xhr.response);
            this.mostViewedData = JSON.parse(data.result);
            console.log(this.mostViewedData);
        }, () => {
            console.log("Error occurred");
        });
    }
}
export function initMostViewed() {
    const mostViewedContainer = document.querySelector('#most-viewed');
    if (mostViewedContainer) {
        new MostViewed(mostViewedContainer);
    }
}
