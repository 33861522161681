// import { Autocomplete } from './Autocomplete';
import { addOebbautocompleteDefaultStyle, createOebbAutocompleteFromRemoteStringArrayWithTopResults, createOebbAutocompleteFromRemoteStringArray } from "./OebbAutocomplete";
import { Observer } from './Observer';
export class SearchToggler extends Observer {
    constructor() {
        super();
        this.body = document.querySelector('body');
        this.searchOpen = false;
        this.html = document.querySelector("html");
        this.searchToggler = document.querySelector('#search-toggler');
        this.isTogglerDeactivated = false;
        this.classNames = {
            searchOpen: 'search-open',
            searchTogglerActive: 'search-toggler--active'
        };
        this.searchToggler.addEventListener('click', (event) => {
            event.preventDefault();
            if (this.isTogglerDeactivated)
                return false;
            this.toggleSearch();
            this.notifyObservers();
        }, false);
        document.addEventListener('closeSearch', (event) => {
            event.preventDefault();
            this.closeSearch();
        }, false);
        this.body.addEventListener('click', (event) => {
            if (!this.searchOpen || this.searchInput.contains(event.target) || this.searchToggler.contains(event.target))
                return;
            this.closeSearch();
        }, false);
        this.searchInput = document.querySelector('#header-search-input');
    }
    closeSearch() {
        if (this.searchOpen) {
            this.toggleSearch();
        }
    }
    toggleSearch() {
        this.html.classList.toggle(this.classNames.searchOpen);
        this.searchToggler.classList.toggle(this.classNames.searchTogglerActive);
        this.searchOpen = !this.searchOpen;
        if (this.searchOpen)
            this.searchInput.focus();
    }
    setTogglerActive() {
        this.searchToggler.classList.add('search-toggler--active');
    }
    deactivateToggler() {
        this.isTogglerDeactivated = true;
    }
    notify() {
        this.closeSearch();
    }
}
export function initSearchToggler(menu) {
    let search = null;
    if (document.querySelector("#search-toggler")) {
        search = new SearchToggler();
        if (menu)
            menu.addObserver(search);
        if (menu)
            search.addObserver(menu);
        const searchInput = document.querySelector('.js-search-input');
        if (searchInput) {
            addOebbautocompleteDefaultStyle();
            const withTopHits = searchInput.getAttribute('data-top-hits') && searchInput.getAttribute('data-top-hits') == "true";
            if (withTopHits) {
                createOebbAutocompleteFromRemoteStringArrayWithTopResults(searchInput, searchInput.dataset.autocompleteUrl.trim() + '?topHits=true&lang=' + searchInput.dataset.lang, {
                    paramKey: searchInput.name,
                    termsKey: 'suggestions',
                    topResultsKey: 'top_results',
                    debounceTiming: 0,
                });
            }
            else {
                createOebbAutocompleteFromRemoteStringArray(searchInput, searchInput.dataset.autocompleteUrl.trim() + '?lang=' + searchInput.dataset.lang, {
                    paramKey: searchInput.name,
                    termsKey: 'suggestions',
                    debounceTiming: 0,
                });
            }
        }
    }
    return search;
}
