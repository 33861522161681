export class SocialmediaWall {
    constructor(element) {
        this.socialmediaElement = element;
        this.socialmediaWallId = this.socialmediaElement.getAttribute('data-socialmedia-wall-id');
        this.disclaimer = this.socialmediaElement.querySelector('.socialmedia-wall__disclaimer');
        this.wallToggler = this.socialmediaElement.querySelector('.socialmedia-wall__btn');
        this.localstorageKey = 'socialmediaWallId-' + this.socialmediaWallId;
        this.initEventListener();
        this.updateTogglerText();
        if (this.wallIsEnabled()) {
            this.removeDisclaimer();
            this.loadSocialWall();
        }
    }
    wallIsEnabled() {
        return localStorage.getItem(this.localstorageKey) ? true : false;
    }
    initEventListener() {
        this.wallToggler.addEventListener('click', (e) => {
            e.preventDefault();
            if (this.wallIsEnabled()) {
                localStorage.removeItem(this.localstorageKey);
                this.removeSocialWall();
                this.addDisclaimer();
            }
            else {
                localStorage.setItem(this.localstorageKey, this.socialmediaWallId);
                this.removeDisclaimer();
                this.loadSocialWall();
            }
            this.updateTogglerText();
        });
    }
    updateTogglerText() {
        if (this.wallIsEnabled()) {
            this.wallToggler.innerHTML = this.wallToggler.getAttribute('data-disable-text');
        }
        else {
            this.wallToggler.innerHTML = this.wallToggler.getAttribute('data-enable-text');
        }
    }
    addDisclaimer() {
        if (this.disclaimer) {
            this.socialmediaElement.insertBefore(this.disclaimer, this.socialmediaElement.firstChild);
        }
    }
    removeDisclaimer() {
        if (this.disclaimer) {
            this.socialmediaElement.removeChild(this.disclaimer);
        }
    }
    loadSocialWall() {
        this.scriptElement = document.createElement('script');
        this.scriptElement.async = true;
        this.scriptElement.defer = true;
        this.scriptElement.classList.add('js-script-wallsio');
        this.scriptElement.src = 'https://walls.io/js/wallsio-widget-1.2.js';
        this.scriptElement.setAttribute('data-wallurl', 'https://my.walls.io/' + this.socialmediaWallId + '?nobackground=1&show_header=0');
        this.scriptElement.setAttribute('data-width', '100%');
        this.scriptElement.setAttribute('data-autoheight', '1');
        this.scriptElement.setAttribute('data-height', '800');
        this.scriptElement.setAttribute('data-lazyload', '1');
        this.scriptElement.setAttribute('data-title', 'Social-Wall');
        this.socialmediaElement.insertBefore(this.scriptElement, this.socialmediaElement.firstChild);
    }
    removeSocialWall() {
        const frame = this.socialmediaElement.querySelector('iframe');
        this.socialmediaElement.removeChild(frame);
        const script = document.querySelector('.js-script-wallsio');
        script.parentNode.removeChild(script);
    }
}
export function initSocialmediaWall() {
    const walls = document.querySelectorAll(".socialmedia-wall");
    for (let i = 0; i < walls.length; i++) {
        new SocialmediaWall(walls.item(i));
    }
}
