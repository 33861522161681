import { isDesktopViewport, isMobileViewport, isTabletViewport } from './Utils';
import { Observer } from './Observer';
export class Menu extends Observer {
    constructor() {
        super();
        this.burgerLink = document.getElementById('burger-link');
        this.mainMenu = document.getElementById('main-menu');
        this.html = document.querySelector('html');
        this.mainNavCloseLink = document.querySelector('.main-nav__close');
        this.menuOpen = false;
        this.menuElements = document.querySelectorAll('.main-item');
        // private langNav: Element = document.querySelector('.lang-nav');
        // private navSubPanelTitle: HTMLElement = document.createElement('span');
        // private navSubPanelBtnBack: HTMLElement = document.createElement('button');
        // private sublevelToggler: NodeList = document.querySelectorAll('.sublevel-item__toggler');
        this.classNames = {
            activeMobileMenu: 'open',
            ocopen: 'ocopen',
            sublevelOpen: 'sublevel__list-item--open',
            langSlideOut: 'lang-nav--slide-out',
            mainItemToggler: 'main-item__toggler',
            mainListItemCurrentOpen: 'main-nav__list-item--current-open',
            mainListItemOpen: 'main-nav__list-item--open',
            mainListItemOpenKeep: 'main-nav__list-item--open-keep',
            // btnBack: 'sublevel__btn-back',
            // btnBackIcon: 'obb-icon-arrow-left',
            // sublevelTitle: 'sublevel__title',
        };
        window.addEventListener('click', () => {
            if (isDesktopViewport()) {
                this.closeAllMenuItems();
            }
            else {
                this.closeMobileMenu();
            }
        });
        document.querySelector('.main-menu').addEventListener('click', (e) => {
            e.stopPropagation();
        });
        this.burgerLink.addEventListener('click', (event) => {
            event.preventDefault();
            event.stopPropagation();
            this.toggleMobileMenu();
            // this.deactivateNavSubPanel();
            this.notifyObservers();
        });
        this.mainNavCloseLink.addEventListener('click', (event) => {
            event.preventDefault();
            this.toggleMobileMenu();
            // this.deactivateNavSubPanel();
        }, false);
        document.addEventListener('keyup', (event) => {
            if (event.keyCode == 27) { // Escape
                if (this.menuOpen) {
                    this.toggleMobileMenu();
                    // this.deactivateNavSubPanel();
                }
            }
        });
        this.createMainToggler(); // Toggles for 2nd level
        // this.activateSublevelToggler(); // Toggles for 3rd level; Third level not implemented
    }
    notify() {
        if (!this.menuOpen)
            return;
        this.toggleMobileMenu();
        // this.deactivateNavSubPanel();
    }
    closeMobileMenu() {
        this.mainMenu.classList.remove(this.classNames.activeMobileMenu);
        this.html.classList.remove(this.classNames.ocopen);
        this.menuOpen = false;
    }
    toggleMobileMenu() {
        this.mainMenu.classList.toggle(this.classNames.activeMobileMenu);
        this.html.classList.toggle(this.classNames.ocopen);
        this.menuOpen = !this.menuOpen;
    }
    // private toggleNavSubPanel(parent: HTMLElement, button: HTMLElement, title?: string): void {
    //     if (parent.classList.contains(this.classNames.sublevelOpen)) {
    //         this.deactivateNavSubPanel();
    //     } else {
    //         this.activateNavSubPanel(parent, button);
    //     }
    // }
    createMainToggler() {
        let mouseEnterTimer;
        for (let i = 0; i < this.menuElements.length; i++) {
            if (i > 0 && this.menuElements[i].parentElement.querySelector('div')) {
                const linkElem = this.menuElements[i];
                let openLink = linkElem.parentElement.querySelector('.' + this.classNames.mainItemToggler);
                if (openLink) {
                    this.setAriaExpandedOnMainMenuItemToggler(openLink);
                    openLink.addEventListener('click', (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.toggleMainMenuItem(openLink);
                    });
                    openLink.parentElement.querySelector('a').addEventListener('mouseenter', () => {
                        if (isMobileViewport() || isTabletViewport())
                            return;
                        mouseEnterTimer = window.setTimeout(() => {
                            this.openMainMenuItemHover(openLink);
                        }, 300);
                    });
                    openLink.parentElement.addEventListener('mouseleave', () => {
                        if (isMobileViewport() || isTabletViewport())
                            return;
                        clearTimeout(mouseEnterTimer);
                        this.closeMainMenuItemHover(openLink);
                    });
                }
            }
        }
    }
    openMainMenuItemHover(link) {
        link.parentElement.classList.add(this.classNames.mainListItemOpen);
        this.setAriaExpandedOnMainMenuItemToggler(link);
    }
    closeMainMenuItemHover(link) {
        link.parentElement.classList.remove(this.classNames.mainListItemOpen);
        this.setAriaExpandedOnMainMenuItemToggler(link);
    }
    toggleMainMenuItem(link) {
        const parent = link.parentElement;
        // Menu item is open because of template rendering
        if (!isDesktopViewport() && parent.classList.contains(this.classNames.mainListItemCurrentOpen)) {
            link.parentElement.classList.remove(this.classNames.mainListItemCurrentOpen);
            link.parentElement.classList.remove(this.classNames.mainListItemOpen);
            link.parentElement.classList.remove(this.classNames.mainListItemOpenKeep);
            this.setAriaExpandedOnMainMenuItemToggler(link);
            return;
        }
        if (parent.classList.contains(this.classNames.mainListItemOpen)
            && !parent.classList.contains(this.classNames.mainListItemOpenKeep)) {
            link.parentElement.classList.remove(this.classNames.mainListItemOpen);
            this.setAriaExpandedOnMainMenuItemToggler(link);
            return;
        }
        if (!parent.classList.contains(this.classNames.mainListItemOpen)
            && parent.classList.contains(this.classNames.mainListItemOpenKeep)) {
            link.parentElement.classList.remove(this.classNames.mainListItemOpenKeep);
            this.setAriaExpandedOnMainMenuItemToggler(link);
            return;
        }
        link.parentElement.classList.toggle(this.classNames.mainListItemOpen);
        link.parentElement.classList.toggle(this.classNames.mainListItemOpenKeep);
        this.setAriaExpandedOnMainMenuItemToggler(link);
    }
    closeAllMenuItems() {
        const openMenuItems = document.querySelectorAll(`.${this.classNames.mainListItemOpen}, .${this.classNames.mainListItemOpenKeep}`);
        for (let i = 0; i < openMenuItems.length; i++) {
            openMenuItems[i].classList.remove(this.classNames.mainListItemOpen);
            openMenuItems[i].classList.remove(this.classNames.mainListItemOpenKeep);
        }
    }
    setAriaExpandedOnMainMenuItemToggler(toggler, value) {
        if (value) {
            toggler.setAttribute('aria-expanded', value);
            return;
        }
        const parent = toggler.parentElement;
        if (parent.classList.contains(this.classNames.mainListItemCurrentOpen)
            || parent.classList.contains(this.classNames.mainListItemOpen)
            || parent.classList.contains(this.classNames.mainListItemOpenKeep)) {
            toggler.setAttribute('aria-expanded', 'true');
        }
        else {
            toggler.setAttribute('aria-expanded', 'false');
        }
    }
}
export function initMenu() {
    if (document.querySelector('#main-menu')) {
        return new Menu();
    }
}
