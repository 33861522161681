export class Expandable {
    // Animation duration in ms
    static get ANIMATION_DURATION() { return 300; }
    constructor(element, onOpen, onClose) {
        this.height = 0;
        this.setOpen = () => {
            this.element.classList.add('expandable--open');
            this.content.style.opacity = '1';
            this.content.style.height = 'auto';
            this.height = this.content.scrollHeight;
        };
        this.setClose = () => {
            this.element.classList.remove('expandable--open');
            this.content.style.opacity = '0.2';
            this.content.style.height = '0px';
        };
        this.element = element;
        this.handler = this.element.querySelector('.js-expandable-handler');
        this.content = this.element.querySelector('.js-expandable-content');
        this.element.ref = this;
        if (this.handler && this.content) {
            this.content.style.height = '0';
            this.content.style.opacity = '0.2';
            this.content.style.overflow = 'hidden';
            this.content.style.transition = `height ${Expandable.ANIMATION_DURATION / 1000}s ease-in-out, opacity ${Expandable.ANIMATION_DURATION / 1000}s ease-in-out`;
            this.handler.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                // Reset height to px value for animation
                this.content.style.height = this.height + 'px';
                this.content.style.height = window.getComputedStyle(this.content).height;
                if (this.element.classList.contains('expandable--open')) {
                    this.content.style.opacity = '0.2';
                    this.content.style.height = '0px';
                    this.element.classList.remove('expandable--open');
                    if (onClose)
                        onClose();
                    this.height = 0;
                    window.clearTimeout(this.timeoutId); // Clear timeout to prevent errors if expandable is triggered and animation is running
                }
                else {
                    this.content.style.opacity = '1';
                    this.content.style.height = this.content.scrollHeight + 'px';
                    this.element.classList.add('expandable--open');
                    if (onOpen)
                        onOpen();
                    this.height = this.content.scrollHeight;
                    this.timeoutId = window.setTimeout(() => {
                        // Set height to 'auto' after animation for nested expandables
                        this.content.style.height = 'auto';
                    }, Expandable.ANIMATION_DURATION);
                }
            });
        }
    }
}
export function initExpandables() {
    const expandables = document.querySelectorAll('.js-expandable');
    for (let i = 0; i < expandables.length; i++) {
        new Expandable(expandables[i]);
    }
}
