import { domReady } from "./DomReady";
import { initMenu } from "./Menu";
import { initSearchToggler } from "./SearchToggler";
import { initParallax } from "./Parallax";
import { initExpandables } from "./Expandable";
import { initCarousels } from "./Carousel";
import { initSearchPage } from "./Search";
import { initFilter } from "./Filter";
import { initLikesCounter } from "./LikesCounter";
import { initTeaserSliders } from "./TeaserSlider";
import { initVideos } from "./Video";
import { initColcades } from "./colcade";
import { jsCheck, tabbingClass, addRoleButtonListener, formatAge } from "./Utils";
import { lazyLoadImages } from "./lazy-load-images";
import { initPlaylist } from "./Playlist";
import { initWaterfall } from "./Waterfall";
import { initSocialmediaWall } from "./SocialmediaWall";
import { initScrollTop } from "./ScrollTop";
import { initMostViewed } from "./MostViewed";
import { initScrollEvents } from "./ScrollEvents";
import { Tracking } from "./Tracking";
import { loadScript, getResourceUrl } from "./Utils";
jsCheck();
domReady(function () {
    lazyLoadImages();
    let menu = initMenu();
    let searchToggler = initSearchToggler(menu);
    tabbingClass('is-tabbing');
    addRoleButtonListener();
    initParallax();
    initExpandables();
    initCarousels();
    initSearchPage(searchToggler);
    initFilter(menu, searchToggler);
    formatAge();
    initLikesCounter();
    initTeaserSliders();
    initVideos();
    initColcades();
    initPlaylist();
    initWaterfall();
    initSocialmediaWall();
    initScrollTop();
    initMostViewed();
    initScrollEvents();
    new Tracking();
    const selectorForStencil = [
        'oebbx-gallery-slider',
        'oebbx-gallery-thumbs',
        'oebbx-cloud-image',
    ].join(',');
    if (document.querySelector(selectorForStencil)) {
        loadScript(getResourceUrl('js/lib/rcg-2019/rcg-2019.esm.js'), 'rcg-2019-module', null, 'module');
    }
});
