import { addOebbDialogModalDefaultStyles, OebbDialogModal } from './OebbDialogModal';
import * as videoDisclaimer from './VideoDisclaimer';
export class Video {
    constructor(element) {
        this.lang = document.documentElement.lang ? document.documentElement.lang : 'de';
        this.element = element;
        this.youTubeVideoId = element.dataset.context;
        if (this.element.classList.contains('js-video--none')) {
            this.element.addEventListener('click', (e) => this.handleClickNone(e));
        }
        else if (this.element.classList.contains('js-video--dialog')) {
            this.element.addEventListener('click', (e) => this.handleClickDialog(e));
        }
        else if (this.element.classList.contains('js-video--link')) {
            this.element.setAttribute('target', '_blank');
            this.element.addEventListener('click', () => this.handleClickLink());
        }
        else {
            this.element.innerHTML = this.getMarkup(this.youTubeVideoId);
            this.initDisclaimerButtonEvents(this.element);
        }
    }
    toggleAllVideos() {
        let allVideos = document.querySelectorAll('.js-video:not(.js-video--dialog):not(.js-video--link):not(.js-video--none)');
        if (allVideos) {
            for (var i = 0; i < allVideos.length; i++) {
                let currentVideo = allVideos.item(i);
                currentVideo.innerHTML = this.getMarkup(currentVideo.dataset.context);
                this.initDisclaimerButtonEvents(allVideos.item(i));
            }
        }
    }
    handleClickNone(e) {
        e.preventDefault();
        const parent = this.element.parentElement;
        const video = document.createElement('div');
        video.classList.add('js-video');
        video.dataset.context = this.youTubeVideoId;
        video.innerHTML = this.getMarkup(this.youTubeVideoId);
        parent.replaceChild(video, this.element);
        this.initDisclaimerButtonEvents(video);
    }
    handleClickDialog(e) {
        e.preventDefault();
        addOebbDialogModalDefaultStyles();
        new OebbDialogModal(this.getMarkup(this.youTubeVideoId));
        this.overlayDialog = document.querySelector('.js-oebb-dm-overlay .js-oebb-dm-dialog');
        this.overlayDialog.setAttribute('data-context', this.youTubeVideoId);
        if (this.overlayDialog) {
            this.initDisclaimerButtonEvents(this.overlayDialog);
        }
    }
    handleClickLink() {
        return; // Just follow the link
    }
    getMarkup(youtubeVideoId) {
        let button = videoDisclaimer.createDisclaimerBtn();
        videoDisclaimer.changeDisclaimerBtnText(button, this.lang);
        if (videoDisclaimer.isYoutubeEnabled()) {
            return `
            <div class="video-container">
                <iframe class="video-iframe" src="https://www.youtube-nocookie.com/embed/${youtubeVideoId}?autoplay=0&fs=0&showinfo=0" title="${''}" allow="autoplay; encrypted-media" allowfullscreen=""></iframe>
            </div> ${button.outerHTML}
        `;
        }
        else {
            return `<div class="js-video-disclaimer video-disclaimer">${videoDisclaimer.createDisclaimerTxt(this.lang).outerHTML} ${button.outerHTML}</div>`;
        }
    }
    initDisclaimerButtonEvents(jsVideo) {
        let disclaimerDialogButton = jsVideo.querySelector('.js-disclaimer-btn');
        if (disclaimerDialogButton) {
            disclaimerDialogButton.addEventListener('click', () => {
                videoDisclaimer.toggleYoutubeLocalstorage();
                jsVideo.innerHTML = this.getMarkup(jsVideo.dataset.context);
                this.initDisclaimerButtonEvents(jsVideo);
                this.toggleAllVideos();
            });
        }
    }
}
export function initVideos() {
    if (document.querySelector('.js-video')) {
        const videos = document.querySelectorAll('.js-video');
        for (let i = 0; i < videos.length; i++) {
            new Video(videos[i]);
        }
    }
}
