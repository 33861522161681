export class Tracking {
    constructor() {
        if (!window._paq)
            return;
        this.trackErrorPages();
    }
    trackErrorPages() {
        const errorPage = document.querySelector('.js-error-page');
        if (errorPage) {
            let backlink = '';
            if (document.location.href.indexOf('fehlerseiten') > -1) {
                backlink = document.referrer.length > 0 ? document.referrer : '';
            }
            else {
                backlink = document.location.href;
            }
            window._paq.push(['trackEvent', 'Error', this.errorPage.getAttribute('data-title'), backlink]);
        }
    }
}
