import { generateTeaser } from './Utils';
import { lazyLoadImages } from './lazy-load-images';
export class Search {
    constructor(form) {
        this.hits = [];
        this.form = form;
        this.input = form.querySelector('.js-search-input');
        this.result = document.querySelector('.js-search');
        this.template = document.querySelector('#teaser-template');
        this.sortButtonsWrapper = document.querySelector('.sortbutton-wrapper');
        this.sortButtonDate = document.querySelector('.sortbutton--date');
        this.sortButtonScore = document.querySelector('.sortbutton--score');
        this.url = this.result.dataset.url;
        this.lang = this.result.dataset.lang ? this.result.dataset.lang : 'de';
        this.cluster = this.result.dataset.cluster;
        this.form.ref = this;
        this.initDom();
        this.initListeners();
    }
    initDom() {
        let searchQuery = this.input.value;
        if (searchQuery) {
            history.replaceState({ 'query': searchQuery }, searchQuery, `?q=${searchQuery}`);
            this.getResults(() => this.renderResult());
        }
        setTimeout(() => {
            this.input.focus();
            this.input.value = '';
            this.input.value = searchQuery;
        }, 200);
    }
    initListeners() {
        this.form.addEventListener('submit', (e) => this.submitForm(e), false);
        window.onpopstate = (e) => {
            if (e.state) {
                this.input.value = e.state.query;
                this.getResults(() => this.renderResult());
            }
        };
        this.sortButtonScore.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.sortButtonScore.classList.add('sortbutton--active');
            this.sortButtonDate.classList.remove('sortbutton--active');
            this.hits = this.sortArrayByKey(this.hits, 'score');
            this.renderResult();
        });
        // Sort by Date
        this.sortButtonDate.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.sortButtonDate.classList.add('sortbutton--active');
            this.sortButtonScore.classList.remove('sortbutton--active');
            this.hits = this.sortArrayByKey(this.hits, 'modifiedDate');
            this.renderResult();
        });
    }
    submitForm(e) {
        if (e)
            e.preventDefault();
        history.pushState({ 'query': this.input.value }, this.input.value, `?q=${this.input.value}`);
        this.getResults(() => this.renderResult());
    }
    getResults(onCompleted) {
        let url = this.url.trim();
        let params = `cluster=${this.cluster}`;
        let xhr = new XMLHttpRequest();
        url += `?q=${this.input.value}&lang=${this.lang}`;
        xhr.open('GET', encodeURI(url), true);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                this.hits = JSON.parse(xhr.response).hits;
                if (onCompleted)
                    onCompleted();
            }
        };
        xhr.send(params);
    }
    renderResult() {
        let count = 0;
        if (this.hits.length > 0) {
            let articles = ``;
            this.result.innerHTML = "";
            this.hits.forEach((hit) => {
                if (this.result.hasAttribute('data-article-filter') && !hit.meta.articleTitle)
                    return; // Not an article
                count++;
                let textarea = document.createElement('textarea');
                textarea.innerHTML = hit.meta.articleLeadin ? hit.meta.articleLeadin : '';
                textarea.value;
                articles += generateTeaser(this.template, {
                    'href': hit.url,
                    'imageSrc': hit.meta.articleImageSrc,
                    'imageSrcSet': hit.meta.articleImageSrcSet,
                    'imageAlt': hit.meta.articleImageAlt,
                    'category': hit.meta.articleCategory ? hit.meta.articleCategory : '',
                    'title': hit.meta.articleTitle ? hit.meta.articleTitle : hit.title,
                    'leadin': textarea.value ? textarea.value : '',
                });
            });
            this.sortButtonsWrapper.style.display = 'block';
            let resultText = (count > 1) ? this.result.dataset.resultPlural : this.result.dataset.resultSingular;
            if (count > 0) {
                this.result.insertAdjacentHTML('beforeend', `
                    <h1>${count} ${resultText}</h1>

                    <div class="row">${articles}</div>
                `);
            }
            lazyLoadImages();
        }
        if (this.hits.length == 0 || count == 0) {
            this.sortButtonsWrapper.style.display = 'none';
            this.result.innerHTML = `
                <h1>${this.result.dataset.resultNoneHeading}</h1>

                <p>${this.result.dataset.resultNoneText}</p>
            `;
        }
    }
    sortArrayByKey(array, key) {
        var getValue = (o, k) => o[k], keys = key.split(".");
        return array.sort((a, b) => keys.reduce(getValue, b) - keys.reduce(getValue, a));
    }
}
export function initSearchPage(searchToggler) {
    if (document.querySelector('.js-search')) {
        if (searchToggler) {
            searchToggler.setTogglerActive();
            searchToggler.deactivateToggler();
        }
        new Search(document.querySelector('.js-search-form'));
    }
}
